.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
  }
  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .navbar-logo img {
    margin-right: 20px;
  }

  .navbar-logo img {
    height: 50px;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navbar-right {
    display: flex;
    gap: 20px;
  }

  .navbar-links a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
  
  .navbar-button {
    background-color: #f9a825;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }