/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #e0f7fa;
  color: #000;
  margin: 0;
  padding: 0;
}
.class-list p {
    color: #000 !important;
}
.class-list {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.class-item {
  display: flex;
  align-items: flex-start;  /* Align items to the top */
  justify-content: start;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
}

.class-item:last-child {
  border-bottom: none;
}

.class-description {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 8px;
}

.class-thumb img {
  max-width: 400px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 20px;
}

.class-info {
  flex: 1;
  text-align: left;
  margin-top: 0;
  padding-top: 0;
}

.class-time {
  font-size: 1em;
  color: #777;
  margin-bottom: 8px;
}

.class-title {
  font-size: 1.6em;
  margin: 5px 0;
  color: #333;
  font-weight: bold;
}

.class-instructor {
  font-size: 1.2em;
  color: #555;
}