/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.home {
  text-align: center;
  padding: 40px 20px;
  background-color: #252525;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 188px);
}

/* Align center both horizontal and vertical */
.home h1 {

  font-size: 4.5em;
  color: #fff;
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif;
}

.home p {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 30px;
  font-family: 'Roboto', Arial, sans-serif;
}